import { disableVersionEnginePowerRatios, enableVersionEnginePowerRatios } from '@/api/aqversionenginepowerratio';

const disableVersionEnginePowerRatio = {
	id: 'disableVersionEnginePowerRatio',
	selectionType: 'multiple',
	label: 'aqversionenginepowerratio.actions.disableVersionEnginePowerRatio',
	functionality: 'UPDATE_AQVERSIONENGINEPOWERRATIO',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqversionenginepowerratio.actions.disableVersionEnginePowerRatio');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqversionenginepowerratioid: registries[i].aqversionenginepowerratioid
			});
		}

		const data = await disableVersionEnginePowerRatios(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableVersionEnginePowerRatio = {
	id: 'enableVersionEnginePowerRatio',
	selectionType: 'multiple',
	label: 'aqversionenginepowerratio.actions.enableVersionEnginePowerRatio',
	functionality: 'UPDATE_AQVERSIONENGINEPOWERRATIO',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqversionenginepowerratio.actions.enableVersionEnginePowerRatio');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqversionenginepowerratioid: registries[i].aqversionenginepowerratioid
			});
		}

		const data = await enableVersionEnginePowerRatios(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableVersionEnginePowerRatio, enableVersionEnginePowerRatio]
};
